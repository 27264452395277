import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import GeneralHeader from '../components/Header/GeneralHeader'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import WhatIsResearch from '../components/WhatIsResearch/WhatIsResearch'
import CaseStudyGrid from '../components/CaseStudy/CaseStudyGrid'
import img1 from '../images/homepage/icons-with-text/10Billion.svg'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'About us',
    url: '/about-us',
  },
  {
    title: 'Case Studies',
    url: '/case-studies',
  },
]

const CaseStudies = () => {
  return (
    <Layout
      activeLink="/case-studies"
      title="R&D Case studies"
      description="R&D Case studies"
    >
      <GeneralHeader breadcrumbData={breadcrumbData} heading="Case studies" />
      <WhatIsResearch
        tag="HOW WE HAVE HELPED OUR CLIENTS"
        heading="Nation’s most successful R&D specialists"
        body={[
          'At RDA we have delivered R&D tax-credit claims for businesses totalling £2 billion in turnover. That is why we are among the nation’s most successful R&D specialists, helping UK businesses stay at the forefront of global industry.But don’t take our word for it. Here are a selection of testimonials demonstrating how our clients have benefited from RDA’s specialist support.',
        ]}
        maxwidth="893px"
      />
      <CaseStudyGrid
        data={[
          {
            img: img1,
            companyName: 'Hilton Food Group (Global food supplier)',
            content:
              '"I had a limited understanding of RDA tax relief before talking to them and I had received a few emails, but I was unsure that it applied to our business.  We initially began to work with RDA and understand them, and we have been pleased with the process and the outcome, achieving great results each time."',
            url: '/hilton-food',
            category: 'Food Supply',
            turnover: 'Annual turnover: £2.77bn',
          },

          {
            img: img1,
            companyName: 'Weston Homes PLC',
            content:
              '"Following some initial conversations with RDA. We looked into the feasibility of submitting a claim on a no win no fee basis. The RDA team took the time to understand our business and met various key people within each division of our companies to establish what information was needed."',
            url: '/weston-homes',
            category: 'Construction Company',
            turnover: 'Annual turnover: £300m',
          },

          {
            img: img1,
            companyName: 'PPS Commercials',
            content:
              '"We hadn’t made any R&D tax credit claims before, and it was our accountant who recommended we speak to RDA. I spoke with the team at RDA and they made us feel totally at ease. As we went through the process it was extremely seamless, all the hard work was taken care of by RDA."',
            url: '/pps-commercials',
            category: 'Body Builders, Automotive specialists',
            turnover: 'Annual turnover: £16m',
          },

          {
            img: img1,
            companyName: 'MLM Group',
            content:
              '"Following some initial conversations with Kevin Auchoybur from RDA and Jim Rudd, an Infrastructure Director from MLM, we looked into the feasibility of submitting a claim on a no-win, no-fee basis. Kevin took the time to understand our business and met the various key people at MLM to establish what information was needed.',
            url: '/mlm-group',
            category:
              'Engineering, environmental & building control consultancy',
            turnover: 'Annual turnover: £24m (2019)',
          },

          {
            img: img1,
            companyName: 'Modus Engineering Services',
            content:
              '"From the outset, Lee, Jim and the team at RDA have been most professional and helpful. Applying expert knowledge, ultimately resulting in a successful claim."',
            url: '/modus-engineering-services',
            category: 'Engineering',
            turnover: 'Annual turnover: n/a',
          },

          {
            img: img1,
            companyName: 'Beaumont Seymour',
            content:
              '"We have been working with Kevin Auchoybur and his team successfully for the last 12 months with many of our clients. The professionalism, knowledge and care applied in relation to all of the R&D Tax Credit claims we have submitted to date is quite simply second to none.',
            url: '/beaumont-seymour',
            category: 'Accountancy',
            turnover: 'Annual turnover: n/a – third-party client support',
          },
          {
            img: img1,
            companyName: 'Skyline Green Interiors',
            content:
              '“We hadn’t made any R&D claims before, and it was our accountant who recommended we speak to RDA. I spoke with Gary Waskett and he made us feel totally at ease – he’s a very personable guy who guided us through things.',
            url: '/skyline-green-interiors',
            category:
              'Engineering, environmental & building control consultancy',
            turnover: 'Annual turnover: n/a – third-party client support',
          },
          {
            img: img1,
            companyName: 'Caps Cases',
            content:
              'Before talking to RDA, we had previously completed our own claims, and whilst we were aware of the scheme, it was viewed as something that wouldn’t be a valuable use of our limited resources.',
            url: '/caps-cases',
            category:
              'Packaging Design & Manufacture',
            turnover: 'Annual turnover: n/a',
          },
          {
            img: img1,
            companyName: 'Hut Architecture',
            content:
              '“I had a limited understanding of R&D tax relief before engaging RDA. I had received a few emails but I wasn’t sure that it applied to our business.',
            url: '/hut-architecture',
            category: '',
            turnover: 'Annual turnover: n/a',
          },
          {
            img: img1,
            companyName: 'Rise Scaffold',
            content:
              '“Before dealing with RDA, my understanding of the R&D scheme was very limited; we had thought it was for medical industries. But having been through the process with them, we are now finding that we are looking at more innovative solutions to projects as we are aware that there could be financial support for applying new methods and designs. ',
            url: '/rise-scaffold',
            category: '',
            turnover: 'Annual turnover: n/a',
          },
          {
            img: img1,
            companyName: 'ECCC',
            content:
              '“I have had the pleasure of knowing Kevin at RDA for many years now and I continuously hear of the success stories of claims the firm have been securing for their clients. ',
            url: '/eccc',
            category: '',
            turnover: 'Annual turnover: n/a',
          },
          {
            img: img1,
            companyName: 'Tom Gamble Sponsorship',
            content:
              '“I have known Kevin at RDA for over 10 years now. Only in the last 18 months have we managed to align the stars and make a sponsorship opportunity happen.',
            url: '/tom-gamble-sponsorship',
            category: '',
            turnover: 'Annual turnover: n/a',
          },
            {
              img: img1,
              companyName: 'Wesley James Ltd',
              content:
                'I was recommend to use RDA by a professional colleague and they have provided an exceptional level of service and knowledge. Compared to other competitors they are by far to easiest for my clients to work with and I would 100% recommend them to anyone looking for assistance on an R&D claim.',
              url: '/wesley-james-ltd',
              category: 'Accountant',
              turnover: 'Annual turnover: n/a',
            }

        ]}
      />
      <GeneralEnquiryForm />
      <CallBackBanner />
    </Layout>
  )
}

export default CaseStudies
