import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import linkedin from '../../images/social-media/linkedin.svg'
import MainGrid from '../styling/MainGrid'
import VerticalPadding from '../styling/VerticalPadding'
import Button from '../shared/Button/Button'
import { scrollTo } from '../../util/functions'
import InlineLink from '../shared/InlineLink'

interface Member {
  img: string
  companyName: string
  content: string
  category?: string
  turnover?: string
  url: string
}
interface MeetProps {
  data: Array<Member>
}
const CaseStudyGrid: FunctionComponent<MeetProps> = ({ data }) => {
  const [showAllCards, toggleCards] = useState(false)

  const [charLimit, setCharLimit] = React.useState(140)

  const formatText = (text: string) => {
    if (text.length < charLimit) {
      return text
    }
    return text.substr(0, charLimit) + '...'
  }
  const loadMore = () => {
    if (showAllCards) {
      setTimeout(() => {
        scrollTo('case-study-grid')
      }, 300)
    }
    toggleCards(!showAllCards)
  }
  return (
    <Container>
      <MainGrid>
        <VerticalPadding>
          <Grid>
            {data &&
              data.map((item, key) => {
                return (
                  <Card key={key}>
                    <ImageContainer>
                      {/* <Image src={item.img} /> */}
                      <CompanyName>{item.companyName}</CompanyName>
                    </ImageContainer>
                    {item.content && (
                      <Content className="p-large">
                        {formatText(item.content)}
                      </Content>
                    )}
                    {item.url && (
                      <InlineLink
                        to={
                          (process.env.BASE === '/' ? '' : process.env.BASE) +
                          item.url
                        }
                        text={'Read more'}
                        marginbottom="33px"
                        icon
                      />
                    )}
                    <Line />
                    {item.category && <Category>{item.category}</Category>}
                    {item.turnover && <Turnover>{item.turnover}</Turnover>}
                  </Card>
                )
              })}
          </Grid>
          <CardsWrapperMobile id={'case-study-grid'}>
            {data &&
              data.map((item, key) => {
                if (key < 2 || showAllCards) {
                  return (
                    <Card key={key}>
                      <ImageContainer>
                        {/* <Image src={item.img} /> */}
                        <CompanyName>{item.companyName}</CompanyName>
                      </ImageContainer>
                      {item.content && (
                        <Content className="p-large">
                          {formatText(item.content)}
                        </Content>
                      )}
                      {item.url && (
                        <InlineLink
                          to={
                            (process.env.BASE === '/' ? '' : process.env.BASE) +
                            item.url
                          }
                          text={'Read more'}
                          marginbottom="33px"
                          icon
                        />
                      )}
                      <Line />
                      {item.category && <Category>{item.category}</Category>}
                      {item.turnover && <Turnover>{item.turnover}</Turnover>}
                    </Card>
                  )
                }
              })}

            {data.length > 3 && (
              <BtnContainer>
                <Button onClick={loadMore}>
                  {!showAllCards ? 'Show all case studies' : 'Show less'}
                </Button>
              </BtnContainer>
            )}
          </CardsWrapperMobile>
        </VerticalPadding>
      </MainGrid>
    </Container>
  )
}

export default CaseStudyGrid

const Container = styled.section``

const Grid = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
    margin: 0px 0 63px;
  }
`
interface CardProps {
  hideMobile?: boolean
}

const Card = styled.div<CardProps>`
  border: 1px solid #eeeeee;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  padding: 27px 33px 66px;
  display: ${props => (props.hideMobile ? 'none' : 'block')};
  @media (min-width: 768px) {
    display: block;
  }
  @media (min-width: 1024px) {
    padding: 47px 40px 54px;
  }
`

const CardsWrapperMobile = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  margin: 0px 0 40px;
  @media (min-width: 768px) {
    display: none;
  }
`

const BtnContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 26px;
`
const CompanyName = styled.h6`
  font-weight: 700;
`

const Image = styled.img`
  height: 40px;
  width: 40px;
`
const Content = styled.p`
  margin-bottom: 40px;

  @media (min-width: 768px) {
    margin-bottom: 58px;
  }
`
const Line = styled.div`
  background: ${theme.grey.keylineDark};
  margin: 0 0 30px;
  height: 1px;
`

const Category = styled.p`
  color: ${theme.brand.blue};
  font-weight: 700;
  margin-bottom: 2px;
`

const Turnover = styled.p`
  color: ${theme.brand.blue};
`

const InlineLinkWrapper = styled.div`
  margin-top: 22px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  @media (min-width: 1024px) {
    margin-top: 47px;
  }
`
